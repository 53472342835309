/* -------------------------------------------------------------------------- 
|                                 bg player                                  
--------------------------------------------------------------------------- */
/* eslint-disable */
import utils from "./utils";

const bgPlayerInit = () => {

  const Selector = {
    DATA_YOUTUBE_EMBED: '[data-youtube-embed]',
    YOUTUBE_EMBED: 'youtube-embed'
  };
  const youtubeEmbedElements = document.querySelectorAll(Selector.DATA_YOUTUBE_EMBED);
  const loadVideo = () => {
  
    (() => {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      tag.onload = setupPlayer;
    }) ();
  
    let player = null;
  
    function setupPlayer() {

      window.YT.ready(function() {
        youtubeEmbedElements.forEach(youtubeEmbedElement => {
    
          const userOptions = utils.getData(youtubeEmbedElement, Selector.YOUTUBE_EMBED);
          const defaultOptions = { 
            videoId : 'hLpy-DRuiz0',
            startSeconds : 1,
            endSeconds : 50
          };
          const options = window._.merge(defaultOptions, userOptions);
                
          const onYouTubePlayerAPIReady =  () => {
            player = new YT.Player(youtubeEmbedElement, {
              videoId: options.videoId, 
              playerVars: {
                autoplay: 1,
                disablekb: 1,
                controls: 0, 
                modestbranding: 1,
                loop: 1, 
                fs: 0, 
                enablejsapi: 0, 
                start: options?.startSeconds,
                end: options?.endSeconds,
                
              },
              events: {
                onReady: e => {
                  e.target.mute();
                  e.target.playVideo(); 
                },
                onStateChange:  e =>  {
                  if (e.data === YT.PlayerState.PLAYING) {
                    document.querySelectorAll(Selector.DATA_YOUTUBE_EMBED).forEach( embedElement => {
                      embedElement.classList.add("loaded");
                    })            
                  }
      
                  if (e.data === YT.PlayerState.ENDED) {
                    // Loop from starting point
                    player.seekTo(options.startSeconds);
                   
                  }
                }
              }
            });
          };
          onYouTubePlayerAPIReady();
        });
      });
    };

  }
  
  if (document.readyState !== "loading") {
    loadVideo();
  } 
  else {
    document.addEventListener("DOMContentLoaded", function() {
      loadVideo();
    });
  }

  /* -------------------------------------------------------------------------- 
  |                                 Adjust BG Ratio                                
  --------------------------------------------------------------------------- */

  const adjustBackgroundRatio = () => {
    const ytElements = document.querySelectorAll( '.yt-video');
    ytElements.forEach( (ytEl)=>{
      
      const ytElement = ytEl;
      const width = ytElement.parentElement.offsetWidth+200;
      const height = (width*9)/16;
      const minHeight = ytElement.parentElement.offsetHeight+112;
      const minWidth = (minHeight*16)/9;

      ytElement.style.width = width+'px'
      ytElement.style.height = height+'px'
      ytElement.style.minHeight = minHeight+'px'
      ytElement.style.minWidth = minWidth+'px'

    })
  };
  adjustBackgroundRatio(); 
  document.addEventListener('scroll', () => adjustBackgroundRatio() );


}
export default bgPlayerInit;

