

/* -------------------------------------------------------------------------- */
/*                                  Count Up                                  */
/* -------------------------------------------------------------------------- */


const preloaderInit = () => {
  const preloader = document.querySelector('.preloader');
  preloader?.classList.add('loaded');
  
  setTimeout(() => { 
    preloader?.remove();
  }, 800);
};

export default preloaderInit;